<template>
  <div
    class="container p0"
    v-title
    id="productDistribution"
    :data-title="$t('i18n.productDistribution')"
  >
    <el-tabs
      v-loading="loading"
      type="border-card"
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane label="全部" name="1"></el-tab-pane>
      <el-tab-pane label="处理中" name="20">
        <template v-for="(ele, index) in elevatorData" :key="index">
          <div class="list">
            <div
              v-for="(ele, index) in elevatorData"
              :key="index"
              @click="selectElevator(ele, index)"
              :class="{ active: activeEleIndex === index }"
            >
              <span class="index">
                {{ index + 1 + (paging.pageNumber - 1) * 10 }}
              </span>
              <div class="item">
                <div>{{ $t("i18n.orderNumber") }}：{{ ele.billNumber }}</div>
                <div>
                  {{ $t("i18n.workOrderStatus") }}：{{ ele.billStatus }}
                </div>
                <div>{{ $t("i18n.handler") }}：{{ ele.billDealer }}</div>
                <div>
                  {{ $t("i18n.elevatorNo") }}：
                  <a href="javascript:void(0);" @click="enterDetail(ele.id)">
                    {{ ele.number }}
                  </a>
                </div>
                <div>{{ $t("i18n.address") }}：{{ ele.alias }}</div>
              </div>
            </div>
          </div>
          <div class="text-center" v-show="total > 0">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane label="未处理" name="10">
        <div class="list">
          <div
            v-for="(ele, index) in elevatorData"
            :key="index"
            @click="selectElevator(ele, index)"
            :class="{ active: activeEleIndex === index }"
          >
            <span class="index">
              {{ index + 1 + (paging.pageNumber - 1) * 10 }}
            </span>
            <div class="item">
              <div>{{ $t("i18n.elevatorNo") }}：{{ ele.number }}</div>
              <div>{{ $t("i18n.address") }}：{{ ele.alias }}</div>
              <div>{{ $t("i18n.timeOfFault") }}：{{ ele.faultTime }}</div>
              <div>
                {{ $t("i18n.faultCode") }}：
                <a href="javascript:void(0);">{{ ele.faultCode }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" v-show="total > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div id="jlMap" :style="{ height: $defined.HEIGHT - 130 + 'px' }"></div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import BMapGL from "BMap";
import eleImg from "../../assets/pointMap/end.png";
import manImg from "../../assets/pointMap/start.png";
export default {
  name: "All",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      jlMap: "",
      activeName: "1",
      elevatorData: [],
      total: 0,
      loading: false,
      paging: { pageNumber: 1, pageSize: 1000 },
      mapPaging: { pageNumber: 1, pageSize: 1000 },
      activeEleIndex: "",
    });

    const initMap = () => {
      var map = (state.jlMap = new BMapGL.Map("jlMap"));
      map.centerAndZoom(new BMapGL.Point(proxy.$point[0], proxy.$point[1]), 5);
      map.enableScrollWheelZoom(true);
      map.enableDragging();
      map.setMapStyleV2({ styleId: "840b8a90f838a1b138b724b9d641bc86" });
    };

    const initData = () => {
      getNotFaultEle();
      getHandleEleMap(20);
      getHandleEleMap(10);
    };

    const handleClick = () => {
      if (state.activeName === "1") {
        state.jlMap.clearOverlays();
        // state.jlMap.centerAndZoom(
        //   new BMapGL.Point(state.point[0], state.point[1]),
        //   5
        // );
        initData();
      } else {
        handleCurrentChange(1);
      }
    };

    const handleCurrentChange = (val) => {
      
      state.paging.pageNumber = val;
      getHandleElePage(state.activeName);
    };

    const getHandleElePage = async (type) => {
      state.loading = true;
      let { data } = await proxy.$api.monitor.eleAllPages(type, state.paging);
      state.elevatorData = data.records;
      state.total = data.total;
      state.loading = false;
      addPointIcon(data.records);
    };

    const selectElevator = (pt, index) => {
      if (index != -1) {
        // 单个电梯点击事件
        state.activeEleIndex = index;
        state.jlMap.clearOverlays();
      }
      var elePosition = new BMapGL.Point(pt.lng, pt.lat);
      var label = pt.number + "/" + pt.alias;
      drawPoint(elePosition, eleImg, label);
      if (pt.mlng && pt.mlat) {
        var manPosition = new BMapGL.Point(pt.mlng, pt.mlat);
        var label1 = pt.mnumber + "/" + pt.billDealer + "/" + pt.telephone;
        drawPoint(manPosition, manImg, label1);
        var polyline = new BMapGL.Polyline([elePosition, manPosition], {
          strokeColor: "#E9212A",
          strokeStyle: "solid",
          strokeWeight: 1,
          strokeOpacity: 0.8,
        });
        state.jlMap.addOverlay(polyline);
        let points = [elePosition, manPosition];
        state.jlMap.setViewport(points);
      }
    };

    const addPointIcon = (data) => {
      state.jlMap.clearOverlays();
      for (let i = 0; i < data.length; i++) {
        var pt = data[i];
        selectElevator(pt, -1);
      }
      // 自动调整地图让标注显示在最佳视野内
      state.jlMap.setViewport(data);
    };

    const drawPoint = (position, img, str) => {
      var icon = new BMapGL.Icon(img, new BMapGL.Size(30, 40));
      var marker = new BMapGL.Marker(position, { icon: icon });
      var label = new BMapGL.Label(str, {
        offset: new BMapGL.Size(20, -10),
      });
      label.setStyle(proxy.$config.labelStyle);
      marker.setLabel(label);
      state.jlMap.addOverlay(marker);
    };

    const getHandleEleMap = async (type) => {
      // 处理中20，未处理10
      const { data } = await proxy.$api.monitor.eleAllError(type);

      // console.log("points:",data);
      if (document.createElement("canvas").getContext) {
        var points = data;
        var options =
          type == 10
            ? {
                // size: BMAP_POINT_SIZE_SMALL,
                // shape: "BMAP_POINT_SHAPE_STAR",
                color: "red",
              }
            : {
                color: "orange",
              };
        // 初始化PointCollection
        var pointCollection = new BMap.PointCollection(points, options);
        // 添加点击事件
        pointCollection.addEventListener("click", function (e) {
          proxy.$api.monitor.eleNotErrorById(e.point.id).then((r) => {
            let info = r.data[0];
            var opts = {
              width: 200,
              height: 100,
              title:
                t("i18n.number") +
                "：<a class='eleNumber' data-sid=" +
                info.id +
                ">" +
                info.number +
                "</a>",
            };
            var infoWindow = new BMap.InfoWindow(
              t("i18n.address") + "：" + info.alias,
              opts
            );
            var pt = new BMap.Point(info.lng, info.lat);
            state.jlMap.openInfoWindow(infoWindow, pt);
            if (!infoWindow.isOpen()) {
              //如果没有打开，则监听打开事件，获取按钮，添加事件
              infoWindow.addEventListener("open", function () {
                infoWindowOnClick();
              });
            } else {
              //如果已经打开，直接获取按钮，添加事件
              infoWindowOnClick();
            }
          });
        });
        state.jlMap.addOverlay(pointCollection);
        if (state.paging.pageNumber < data.pages) {
          state.paging.pageNumber++;
          getNotFaultEle();
        }
      } else {
        alert("请在chrome、safari、IE8+以上浏览器查看本示例");
      }
    };
    const infoWindowOnClick = () => {
      var btn = document.getElementsByClassName("eleNumber");
      for (var i = 0; i < btn.length; i++) {
        btn[i].onclick = function (e) {
          let id = e.target.dataset.sid;
          enterDetail(id);
        };
      }
    };

    const getNotFaultEle = async () => {
      var allPoints = [];
      var points = [];
      var options = {
        // size: BMAP_POINT_SIZE_SMALL,
        shape: "BMAP_POINT_SHAPE_STAR",
        color: "green",
      };
      
      const { data } = await proxy.$api.monitor.eleNotError(state.paging);
      // 判断当前浏览器是否支持绘制海量点
      if (document.createElement("canvas").getContext) {
        points = data.records;

        allPoints= allPoints.concat(points);
        console.log(allPoints.length)
        // // 初始化PointCollection
        var pointCollection = new BMap.PointCollection(allPoints, options);
        // 添加点击事件
        pointCollection.addEventListener("click", function (e) {
          proxy.$api.monitor.eleNotErrorById(e.point.id).then((r) => {
            let info = r.data[0];
            var opts = {
              width: 200,
              height: 100,
              title:
                t("i18n.number") +
                "：<a class='eleNumber' data-sid=" +
                info.id +
                ">" +
                info.number +
                "</a>",
            };
            var infoWindow = new BMap.InfoWindow(
              t("i18n.address") + "：" + info.alias,
              opts
            );
            var pt = new BMap.Point(info.lng, info.lat);
            state.jlMap.openInfoWindow(infoWindow, pt);
            if (!infoWindow.isOpen()) {
              //如果没有打开，则监听打开事件，获取按钮，添加事件
              infoWindow.addEventListener("open", function () {
                infoWindowOnClick();
              });
            } else {
              //如果已经打开，直接获取按钮，添加事件
              infoWindowOnClick();
            }
          });
        });
        state.jlMap.addOverlay(pointCollection);
        if (state.paging.pageNumber < data.pages) {
          await requestPoints(pointCollection,allPoints);
        }
        
      } else {
        alert("请在chrome、safari、IE8+以上浏览器查看本示例");
      }
    };
    const enterDetail = (id) => {
      let routeData = router.resolve({
        path: "/realTime/detail",
        query: { id: id },
      });
      window.open(routeData.href, "_blank");
    };
    const requestPoints = async (pc, points) => {
      
      // pc.setPoints(points);
      var {data:newData} = await proxy.$api.monitor.eleNotError(state.paging);
      points = points.concat(newData.records);
      pc.clear()
      pc.setPoints(points)
    if (state.paging.pageNumber < newData.pages) {  
      
      state.paging.pageNumber++;
      await requestPoints(pc,points);
    }
}
    onMounted(() => {
      initMap();
      initData();
    });

    return {
      ...toRefs(state),
      enterDetail,
      handleClick,
      handleCurrentChange,
      selectElevator,
    };
  },
};
</script>

<style lang="scss">
#productDistribution {
  .el-tabs {
    position: absolute;
    width: 4 * $width + 20;
    z-index: 99;
    top: 30px;
    background: $white;
    left: 31px;
    font-size: $font - 2;
    box-shadow: 0px 3px 10px 0px rgba(30, 111, 72, 0.35);
    .el-tabs__content {
      padding: 0;
    }
    .el-tabs__content {
      max-height: 280px;
    }
    .list {
      padding: 5px;
      height: 250px;
      overflow-y: auto;
      .index,
      .item {
        display: inline-block;
        vertical-align: top;
      }
      .active {
        background: rgba(204, 204, 204, 0.5);
        color: #333;
      }
      > div {
        padding: 10px;
        cursor: pointer;
      }
      .item {
        padding-left: 10px;
      }
    }
  }
  .redDot,
  .yellowDot {
    width: 10px;
    height: 10px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }
  // .redDot {
  //   background: #d02c2f;
  //   animation: scale 2s infinite;
  // }
  .yellowDot {
    background: #fdbf01;
  }
  @keyframes scale {
    @for $i from 0 through 10 {
      #{$i * 10%} {
        transform: scale($i / 10);
        opacity: $i / 10;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #productDistribution {
    margin-top: -20px;
    .el-tabs {
      top: 10px;
      left: 10px;
      width: 300px;
    }
  }
}
</style>
